import "core-js/modules/es.function.name.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import CommonMixin from "@/core/plugins/common-mixin";
export default {
  name: "engineer-address",
  mixins: [CommonMixin],
  props: {
    engineer: {
      type: Object,
      required: true,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      fieldDescriptions: [{
        field: "product_type",
        title: "Product Type",
        description: "Select if this item is a physical good or a service you're offering.",
        footer: "Note",
        footer_content: "Once you include this items in a transaction, you cannot change its type."
      }]
    };
  },
  computed: {
    getCountryName: function getCountryName() {
      if (this.lodash.isEmpty(this.engineer) === false && this.lodash.isEmpty(this.engineer.country) === false) {
        return this.engineer.country.name;
      }

      return null;
    }
  }
};