import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.function.name.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import CommonMixin from "@/core/plugins/common-mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"; //import Delete from "@/view/pages/partials/Delete.vue";

import PersonalInfo from "@/view/pages/engineer/detail/Personal-Info";
import EmergencyContact from "@/view/pages/engineer/detail/Emergency-Contact";
import EngineerAddress from "@/view/pages/engineer/detail/Engineer-Address";
import Color from "@/view/pages/engineer/detail/Color";
import EngineerMixin from "@/core/lib/engineer/engineer.mixin";
import Status from "@/view/pages/partials/Status.vue";
import PasswordUpdate from "@/view/pages/engineer/PasswordUpdate"; // import Tickets from "@/view/pages/engineer/Tickets";

import Tickets from "@/view/pages/partials/Detail/Tickets";
import VisitListingTemplate from "@/view/pages/visit/Visit-Listing-Template";
export default {
  mixins: [CommonMixin, ValidationMixin, EngineerMixin],
  name: "engineer-detail",
  title: "Engineer",
  data: function data() {
    return {
      detail: new Object(),
      engineer: 0,
      panel: 0,
      engineerTab: null,
      pageLoading: true,
      deleteDialog: false,
      engineerArr: {}
    };
  },
  components: {
    Tickets: Tickets,
    Status: Status,
    VisitListingTemplate: VisitListingTemplate,
    PersonalInfo: PersonalInfo,
    EmergencyContact: EmergencyContact,
    EngineerAddress: EngineerAddress,
    Color: Color,
    PasswordUpdate: PasswordUpdate
  },
  methods: {
    deleteEngineer: function deleteEngineer() {
      var _this = this;

      if (!_this.$refs.engineerDeleteForm.validate()) {
        return false;
      }
    }
  },
  mounted: function mounted() {
    var _this = this;

    _this.getEngineer().then(function (response) {
      _this.engineerArr = response;

      _this.$store.dispatch(SET_BREADCRUMB, [{
        title: "Staff",
        route: "engineer"
      }, {
        title: "Detail"
      }, {
        title: response.barcode
      }]);
    }).catch(function (error) {
      _this.logError(error);

      _this.$router.go(-1);
    }).finally(function () {
      _this.pageLoading = false;
    });
  },
  created: function created() {
    var _this = this;

    _this.engineer = _this.$route.params.id;

    if (!_this.engineer || _this.engineer <= 0) {
      _this.$router.go(-1);
    }
  },
  computed: {
    getTitle: function getTitle() {
      var result = [];

      if (this.engineerArr && this.engineerArr.customer) {
        result.push(this.engineerArr.customer.display_name);
      }

      if (this.engineerArr && this.engineerArr.customer && this.engineerArr.customer.default_person) {
        result.push(this.engineerArr.customer.default_person.display_name);
      }

      if (this.engineerArr && this.engineerArr.barcode) {
        result.push(this.engineerArr.barcode);
      }

      return result.join(" • ");
    },
    countryName: function countryName() {
      var result = "";

      if (this.lodash.isEmpty(this.engineerArr.country) === false) {
        result = this.engineerArr.country.name;
      }

      return result;
    },
    getStatus: function getStatus() {
      return this.lodash.isEmpty(this.engineerArr) === false ? this.engineerArr.activated : false;
    },
    getBarcode: function getBarcode() {
      return this.lodash.isEmpty(this.engineerArr) === false ? this.engineerArr.barcode : false;
    },
    getRouteParam: function getRouteParam() {
      var result = {
        customer: 0,
        id: 0
      };

      if (this.lodash.isEmpty(this.engineerArr) === false) {
        if (this.engineerArr.id) {
          result.id = this.engineerArr.id;
        }

        if (this.engineerArr.customer) {
          result.customer = this.engineerArr.customer.id;
        }
      }

      return result;
    }
  }
};