//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import CommonMixin from "@/core/plugins/common-mixin";
export default {
  name: "address",
  mixins: [CommonMixin],
  props: {
    engineer: {
      type: Object,
      required: true,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      fieldDescriptions: [{
        field: "product_type",
        title: "Product Type",
        description: "Select if this item is a physical good or a service you're offering.",
        footer: "Note",
        footer_content: "Once you include this items in a transaction, you cannot change its type."
      }]
    };
  }
};